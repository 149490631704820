.handshake-welcome {
  /* background-color: #1d1e4a; */
  /* background-image: url("../../../public/fi_565769.png"),
    url("../../../public/fi_565769_1.png"); */
  background-repeat: no-repeat, no-repeat;
  background-position: right 0% top 0%, left -25% top 20%;
}
.text-nlco-secondary {
  color: #1d1e4a !important;
  font-weight: 700;
  font-size: 2rem;
}
.handshake-welcome p.text-white {
  font-size: 14px;
  font-weight: 600;
}
.day-div {
  font-size: 12px;
  cursor: pointer;
  color: #000;
  font-weight: 600;
}
.day-div:not(:last-child) {
  /* border-right: 2px solid #ffffff; */
  border-right: 2px solid #000;
}
.days-container {
  /* background-color: #1a1b43; */
  background-color: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
}
.active {
  color: #59c1c6;
}

.card {
  background-color: transparent;
  border-radius: 0px;
  border-width: 2px;
}

.card-header {
  font-weight: 600;
  /* background-color: #1a1b43; */
  background-color: transparent;
  min-height: 100px;
  align-items: center;
  display: flex;
  color: #1d1e4a;
}
.card-body {
  font-weight: 600;
}
.timeCol {
  height: 400px;
  align-items: center;
  display: flex;
  color: #1d1e4a;
}
.event-title {
  color: #59c1c6;
  font-weight: 700;
  margin-top: 20px;
}
.panelist-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.panelist-img:hover {
  border: 2px solid #f7b021;
}

.bg-dark-blue {
  /* background-color: #1a1b43; */
  background-color: transparent;
}

.tooltip .tooltip-arrow {
  display: block;
  width: 0.8rem;
  height: 0.4rem;
  color: #f7b021;
}

.popover {
  color: #f7b021;
  background-color: #f7b021;
}

.popover-body {
  color: #ffffff;
}

.popover-arrow {
  color: #f7b021;
}

.tooltip-arrow::before {
  color: #f7b021 !important;
}

.tooltip::before {
  color: #f7b021;
}

.tooltip-inner {
  background-color: #f7b021 !important;
  color: #ffffff;
}

@media (min-width: 768px) and (max-width: 881px) {
  .timeCol {
    height: 450px;
  }
  .event-title {
    font-size: 14px;
  }
  .cb-fs-16 {
    font-size: 12px;
    text-transform: capitalize;
  }
  .cb-fs-18 {
    font-size: 12px;
  }
  .cb-datetime {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .text-nlco-secondary {
    font-weight: 700;
    font-size: 1.5rem;
  }
  .hw .card {
    margin: 0px 15px;
  }
  .bg-dark-blue {
    /* background-color: #1d1e4a; */
    background-color: transparent;
  }
  .cb-border-bottom {
    border: 2px solid;
  }
  .timeCol {
    height: 400px;
    display: block;
  }
  .event-title {
    font-size: 20px;
  }
  .cb-fs-16 {
    font-size: 16px;
    text-transform: capitalize;
  }
  .cb-fs-18 {
    font-size: 18px;
  }
  .nav-tabs {
    border-bottom: none !important;
  }
  .card {
    border: none !important;
  }
  .cb-datetime {
    font-size: 18px;
    font-weight: 600;
    /* color: #ffffff; */
    color: #000;
  }
}
