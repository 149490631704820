nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f7b021;
  z-index: 99999;
}

nav.title {
  font-weight: 600;
  text-decoration: none;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: flex;
  text-decoration: none;
  padding: 0.875rem 1.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  color: #1d1e4a;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2, 1rem);
  padding-left: 1.5rem;
  color: #1d1e4a;
}

.social a {
  color: #1d1e4a;
}

.sticky-navbar {
  transition: background-color 0.3s ease;
}

.navbar-sticky {
  background-color: #f7b021 !important;
  width: 100%; /* Set width to 100% */
  height: 50px; /* Default Bootstrap Navbar height */
}
.navbar-sticky a {
  color: #1d1e4a;
}

.navbar-sticky .navbar-brand,
.navbar-sticky .navbar-nav .nav-link {
  color: #1d1e4a;
}

.navbar-sticky .navbar-toggler-icon {
  background-color: #1d1e4a; /* Change this if you want a different color for the toggler icon */
}

.navbar-transparent {
  background-color: transparent; /* make navbar transparent untill it is scorlled */
}
