footer {
  background-color: #f7b021;
}
.footer-ul {
  padding: 0px;
}
.footer-ul li {
  list-style: none;
}
.footer-ul li a {
  text-decoration: none;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
}
.footer-ul li span {
  font-size: 10px;
  color: #1d1e4a;
  font-weight: 500;
}
.footer-social-links {
  width: 30px;
  height: 30px;
  background-color: #e1a01e;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
  text-align: center;
  font-size: 19px;
}
.footer-social-icon {
  padding: 2px;
}
.back-to-top-icon {
  width: 40px;
  height: 40px;
  background-color: #e1a01e;
  border-radius: 25%;
  font-size: 19px;
  background-color: #1d1e4a;
  color: #f7b021;
}
.back-to-top-text {
  font-size: 12px;
  font-weight: 700;
  color: #1d1e4a;
}

@media (max-width: 767px) {
  .footer-ul li a {
    font-size: 15px;
  }
  .footer-ul li span {
    font-size: 15px;
  }
}
