.custom-checkbox {
    display: flex;
    position: relative;
    padding-left: 20px; /* Adjust as needed based on the size of your custom icon */
    cursor: pointer;
  }
  
  .custom-checkbox input {
    opacity: 0;
    position: absolute;
  }

.custom-checkbox .label {
    margin-left: 10px; /* Adjust spacing as needed */
    display: inline-flex;
  }

  .label {
    color: #5D6676;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: -0.0175rem;
  }

  .checkmark {
    position: absolute;
    top: 0.19rem;
    left: 0;
    border-radius: 0.2rem;
    height: 1rem; /* Adjust as needed based on the size of your custom icon */
    width: 1rem; /* Adjust as needed based on the size of your custom icon */
    border: 2px solid #344054; /* Default border color */
    background-color: #fff; /* Default background color */
  }
  
  .custom-checkbox.checked .checkmark {
    background-color: #F7B021; /* Change background color when checked */
    border: 2px solid #F7B021; /* Change border color when checked */
    color: #fff; /* Change color of the custom icon when checked */
  }
  