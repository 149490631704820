.form {
  display: flex;
  width: 50rem;
  position: relative;
  padding: var(--spacing-6, 2.5rem);
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
  gap: var(--spacing-6, 2.5rem);
  background: var(--Background-White, #fff);
  box-shadow: 0px 1px 8px 0px rgba(16, 24, 40, 0.06),
    0px 1px 10px 0px rgba(16, 24, 40, 0.1);
}

.partSuccesMess {
  color: #5d6676;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: -0.0225rem;
}

.partBtnScss {
  display: flex;
  width: 50%;
  padding: 0.6rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: var(--Radius-sm, 0.25rem);
  background: #5bc5c9;
  color: var(--Brand-Primary, #1d1e4a);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.125rem */
  letter-spacing: -0.015rem;
  float: right;
}

#reg {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}

#reg span {
  color: #f7b021;
}

#regIn,
.select {
  display: flex;
  height: 3.3125rem;
  width: 100%;
  padding: var(--spacing-2, 1rem);
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  flex: auto;
  border: none;
  border-bottom: 0.6px solid var(--Borders-Stroke, #c0c4ca);
  color: #252d3c;
}

.select {
  display: flex;
  height: 3.3125rem;
  width: 100%;
  padding: var(--spacing-2, 1rem);
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  flex: auto;
  border: none;
  border-bottom: 0.6px solid var(--Borders-Stroke, #c0c4ca);
  color: #252d3c;
}

input:focus {
  outline: none;
}

h1 {
  color: var(--Text-Text-Primary, #161b23);
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 2.4rem */
  letter-spacing: -0.04rem;
}

.register {
  position: relative;
  display: inline-block;
}

#regP {
  flex: 1 0 0;
  color: var(--Text-Text-Secondary, #252d3c);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.02rem;
}

.regBtn {
  display: flex;
  width: 8.625rem;
  padding: 0.6rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: var(--Radius-sm, 0.25rem);
  background: #5bc5c9;
  color: var(--Brand-Primary, #1d1e4a);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.125rem */
  letter-spacing: -0.015rem;
}

.greyState {
  display: flex;
  padding: 0.6rem 1.5rem;
  width: 8.625rem;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.015rem;
  gap: 0.25rem;
  flex: 1 0 0;
  color: #a2a7b0;
  border-radius: var(--Radius-sm, 0.25rem);
  background: var(--Background-Disabled-BG, #c0c4ca);
}

#regIn::placeholder,
.choose {
  color: var(--Text-Text-Tertiary, #5d6676);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.0175rem;
}

#regIn[type] {
  color: var(--Text-Text-Tertiary, #252d3c);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.0175rem;
}

/*for card*/
.modal-content {
  max-height: 80vh; /* Set a maximum height for the modal content */
}

.card {
  margin-bottom: 15px; /* Add some space between cards */
}

.card-img-top {
  max-width: 100%; /* Ensure the image doesn't exceed the card width */
  height: auto; /* Maintain aspect ratio */
}

.errorInput {
  border-bottom: 1px solid #ff9d83 !important;
}

.errorText {
  color: #ff3932;
  margin-top: 5px;
  font-size: 12px;
}

.error {
  color: #ff3932;
  margin-top: 5px;
  font-size: 1rem;
}

@media (max-width: 820px) {
  .form {
    display: flex;
    width: 100%;
    padding: 0rem var(--spacing-2, 1rem);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-5, 2rem);
    box-shadow: none;
  }
  h1 {
    flex: 1 0 0;
    color: var(--Text-Text-Primary, #161b23);
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    line-height: 150%; /* 1.875rem */
    letter-spacing: -0.025rem;
  }
  #regP {
    flex: 1 0 0;
    color: var(--Text-Text-Primary, #161b23);
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    line-height: 150%; /* 1.5rem */
    letter-spacing: -0.02rem;
  }
  .partner {
    display: flex;
    width: 23.4375rem;
    padding: 0rem var(--spacing-2, 1rem);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-5, 2rem);
  }
  .lastInput {
    width: 100%;
  }
  .regBtn {
    width: 100%;
  }
  .greyState {
    width: 100%;
  }
}

/*mobile reminder scroll effect*/
@media (max-width: 820px) {
  .modal-scroll-animation {
    overflow-x: scroll;
    white-space: nowrap;
    transition: transform 0.5s ease-in-out;
  }

  .modal-scroll-animation .row {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .modal-scroll-animation .col-4 {
    flex: 0 0 auto;
    width: calc(33.3333% - 1rem);
    margin-right: 1rem;
  }

  .modal-scroll-animation .col-4:last-child {
    margin-right: 0;
  }
}

@media (max-width: 568px) {
  .portCon {
    width: 23.4375rem;
  }
  .partBtn {
    width: 100%;
  }
  .partBtnScss {
    width: 100%;
  }
  .partLabel div span {
    margin-top: 1rem;
  }
}
