.hashtag {
  /* background-color: #151535; */
  background-color: transparent;
  border: 1px solid;
}
.tag {
  font-weight: 800;
  font-size: 28px;
}

@media (max-width: 767px) {
  .hashtag .tag {
    font-size: 23px !important;
  }
  .hashtag .dot {
    font-size: 30px;
  }
}
