.contacts {
  width: 100%;
  height: 100%;
  background-color: #1d1e4a;
  position: relative;
}

.hero {
  position: relative;
  padding: 6.875rem 7.5rem var(--spacing-7, 3rem) 7.5rem;
  max-width: 80rem;
}

.info,
p {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.info h1 {
  color: #f7b021;
}

.info p,
.title p,
label p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: -0.02rem;
}

.conInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
}

.conInfo h1 {
  display: flex;
}

input[type] {
  color: #fff;
}

.conInput {
  display: flex;
  height: 3.3125rem;
  width: 100%;
  padding: var(--spacing-2, 1rem);
  align-items: center;
  align-self: stretch;
  justify-content: center;
  flex: auto;
  border: none;
  border-bottom: 0.6px solid var(--Borders-Stroke, #c0c4ca);
  color: #252d3c;
  background-color: #1d1e4a;
}

.conInput::placeholder {
  color: #ebecee;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.0175rem;
}

#regBtn {
  display: flex;
  width: 8.625rem;
  padding: 0.5rem 1.3rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: var(--Radius-sm, 0.25rem);
  background: #5bc5c9;
  color: var(--Brand-Primary, #1d1e4a);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.125rem */
  letter-spacing: -0.015rem;
  float: right;
}

button {
  background: none;
  border: none;
  text-decoration: none;
  font-size: 12px;
}

input,
textarea:focus {
  outline: none;
}

.heroImage {
  background-size: cover;
  position: relative;
  height: 175px;
  background-position: center;
  background-repeat: no-repeat;
}

.heroText {
  position: absolute;
  bottom: 0.5rem;
  left: 2rem;
}

label {
  display: inline-flex;
  align-self: stretch;
}

label p {
  font-size: 0.875rem;
  align-self: stretch;
}

label p span {
  color: #e8342e;
}

.partBtnScss {
  display: flex;
  width: 50%;
  padding: 0.6rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: var(--Radius-sm, 0.25rem);
  background: #5bc5c9;
  color: var(--Brand-Primary, #1d1e4a);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.125rem */
  letter-spacing: -0.015rem;
  float: right;
}

.errorText {
  color: #ff3932;
  margin-top: 5px;
  font-size: 12px;
}

.heroText h1 {
  color: var(--Color, #fff);
  font-size: 3rem;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 3.6rem */
  letter-spacing: -0.06rem;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
}

.title h1,
p {
  color: white;
  width: 100%;
}

.title span {
  color: #f7b021;
}

#cont {
  display: grid;
  gap: 2.5rem;
}

/* Mobile View */
@media (max-width: 860px) {
  .contacts {
    height: 100%;
    width: 100%;
  }
  .heroImage {
    height: 7.5rem;
  }
  .hero {
    padding: 1rem;
  }
  .heroText {
    left: 1rem;
  }
  .heroText h1 {
    font-size: 1.5rem;
    font-weight: 800;
  }
  .conInfo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 767px) {
  .px2rem {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 568px) {
  .partBtnScss {
    width: 100%;
  }
}
